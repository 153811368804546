import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useFormFields } from '../lib/hooksLib';
import { onError } from '../lib/errorLib';
import { Button, Form } from 'react-bootstrap';

import LoaderButton from "../components/LoaderButton";
import { BsCheck } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import "./ResetPassword.css";
import { apiGet } from '../lib/apiLib';

export default function ResetPassword() {
    const [fields, handleFieldChange] = useFormFields({
        code: "",
        email: "",
        password: "",
        confirmPassword: ""
    });
    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);

    const validateCodeForm = () => fields.email.length > 0;
    const validateResetForm = () => fields.code.length > 0 && fields.password.length > 0 && fields.password === fields.confirmPassword;

    const handleSendCodeClick = async e => {
        e.preventDefault();

        setIsSendingCode(true);

        try {
            await Auth.forgotPassword(fields.email);
            setCodeSent(true);
        } catch (error) {
            onError(error);
            setIsSendingCode(false);
        }
    }

    const handleConfirmClick = async e => {
        e.preventDefault();

        setIsConfirming(true);

        try {
            await Auth.forgotPasswordSubmit(
                fields.email,
                fields.code,
                fields.password
            );
            setConfirmed(true);
        } catch (error) {
            onError(error);
            setIsConfirming(false);
        }
    }

    const handleCreateUserApi = async e => {
        e.preventDefault();

        try {
            await apiGet('routebot','/user/new')
        } catch (error) {
            onError(error);
        }
    }

    const renderRequestCodeForm = () => {
        return <Form onSubmit={handleSendCodeClick}>
            <Form.Group size="large" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                    autoFocus
                    type="email"
                    value={fields.email}
                    onChange={handleFieldChange}
                />
            </Form.Group>
            <LoaderButton
                block
                type="submit"
                // size="lg"
                className='my-3'
                isLoading={isSendingCode}
                disabled={!validateCodeForm()}
            >
                Send Confirmation
            </LoaderButton>
        </Form>
    };

    const renderConfirmationForm = () => {
        return <Form onSubmit={handleConfirmClick}>
            <Form.Group controlId="code">
                <Form.Label>Confirmation Code</Form.Label>
                <Form.Control 
                    autoFocus
                    type="tel"
                    value={fields.code}
                    onChange={handleFieldChange}
                />
                <Form.Text>
                    Please check your email ({fields.email}) for the confirmation code.
                </Form.Text>
            </Form.Group>
            <hr />
            <Form.Group controlId="password">
                <Form.Label>New Password</Form.Label>
                <Form.Control 
                    type="password"
                    value={fields.password}
                    onChange={handleFieldChange}
                />
            </Form.Group>
            <Form.Group controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control 
                    type="password"
                    value={fields.confirmPassword}
                    onChange={handleFieldChange}
                />
            </Form.Group>
            <LoaderButton
                block
                type="submit"
                className="my-3"
                isLoading={isConfirming}
                disabled={!validateResetForm()}
            >
            Confirm
            </LoaderButton>
            <Button onClick={handleCreateUserApi}>Try the API!</Button>
        </Form>
    }

    const renderSuccessMessage = () => {
        return <div className="success">
            <p><BsCheck size={16} /> Your password has been reset.</p>
            <p>
                <Link to="/login">
                    Click here to login with your new credentials.
                </Link>
            </p>
        </div>
    }

  return (
    <div className="ResetPassword">
      {!codeSent
        ? renderRequestCodeForm()
        : !confirmed
        ? renderConfirmationForm()
        : renderSuccessMessage()}
    </div>
  );

}