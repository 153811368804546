import React from 'react';
import { float2curr } from "../lib/currLib";

export default function EditRecoRevenueDisplay({ revenue, oldRevenue }) {

    const revenueDifference = revenue - oldRevenue;
    const revenueGainFlg = revenueDifference >= 0;
    const chgColor = revenueGainFlg ? 'green' : 'red';
    const chgStyle = {
        display: 'inline',
        color: chgColor
    };

    return <div style={{ display: 'inline' }}>
        <strong>New AWR: {float2curr(revenue)} </strong>
        <div style={chgStyle}>({revenueGainFlg ? '+' : '-'}{float2curr(Math.abs(revenueDifference))})</div>
    </div>
}