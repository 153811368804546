import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { FaRobot } from "react-icons/fa";
import "./App.css";
import Routes from "./Routes";
import { AppContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { onError } from "./lib/errorLib";
import { useSelector } from "react-redux";
import RouteSelect from "./components/RouteSelect";

function App() {
  const navigate = useNavigate();

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [selectedRoute, setSelectedRoute] = useState(undefined);
  const [showRouteSelect, setShowRouteSelect] = useState(false);


  useEffect(() => {
    onLoad();
  }, [selectedRoute]);

  async function onLoad() {
    try {
      await Auth.currentSession();

      userHasAuthenticated(true);
      setSelectedRoute(localStorage["selectedRoute"]);
      // console.log("Selected Route is: " + selectedRoute);

      navigate('/'); 
    } catch (e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  const handleModalClick = e => {
    setShowRouteSelect(true);
  };

  const handleModalClose = e => {
    e.preventDefault();
    setShowRouteSelect(false);
  }

  const handleLogout = async () => {
    await Auth.signOut();

    userHasAuthenticated(false);

    navigate('/login');
  }

  return (
    !isAuthenticating && (
      <div className="App container py-3">
        <Navbar
          collapseOnSelect
          bg="dark"
          variant="dark"
          expand="md"
          className="mb-3 px-3"
          sticky="top"
        >
          <LinkContainer to="/">
            <Navbar.Brand className="font-weight-bold text-muted">
              <FaRobot /> <span className="ml-2 font-weight-bold">Routebot</span>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          {isAuthenticated &&
            <Navbar.Text className="justify-content-end">
              {
                selectedRoute // need to check for authenticated too!
                ?
                "Current Route is: " + selectedRoute
                :
                "Please select a Route to analyze"
              }
            </Navbar.Text> 
          }
          <Navbar.Collapse className="justify-content-end">
            <Nav activeKey={window.location.pathname}>
              {isAuthenticated ? (
                  <Nav>
                    <Nav.Link onClick={handleModalClick}>Change Route</Nav.Link>
                      <RouteSelect
                        show={showRouteSelect}
                        handleModalClose={handleModalClose}
                        currentlySelectedRoute={selectedRoute}
                      />
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  </Nav>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, selectedRoute }}>
          <Routes />
        </AppContext.Provider>
      </div>
    )
  );
}

export default App;