import { configureStore, createSlice } from '@reduxjs/toolkit';

const initialState = {};

const recosSlice = createSlice({
    name: 'recos',
    initialState,
    reducers: {
        update: (recos, { payload }) => {
            const { item, updatedReco } = payload;
            recos[item] = updatedReco;
        },
        toggleComplete: (recos, { payload }) => {
            const { item } = payload;
            const itemToToggle = recos[item];
            const readyToKey = itemToToggle.key_rdy;
            recos[item] = {
                ...itemToToggle,
                key_rdy: !readyToKey
            }
        },
        // reset: () => initialState
    }
});
    
const recoEditor = createSlice({
    name: 'editReco',
    initialState: null,
    reducers: {
        edit: (state, { payload }) => payload.item,
        finishEdit: () => null
    }
})


export const {
    update: updateReco,
    toggleComplete,
    // reset: resetState
} = recosSlice.actions;

export const {
    edit: editReco,
    finishEdit: finishEditReco
} = recoEditor.actions;

export default configureStore({
    reducer: {
        recos: recosSlice.reducer,
        editReco: recoEditor.reducer
    }
});