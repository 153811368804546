import React from 'react';
import { Button, Row, Col, Modal, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editReco, updateReco } from '../redux/recoStore';

export default function EditSetupsFooter(props) {
    const {
        handleCancel,
        setups
    } = props;

    const dispatch = useDispatch();
    const thisReco = useSelector(state => state.editReco);

    const handleSave = e => {

        e?.preventDefault();
        if (thisReco && setups) {
            dispatch(updateReco({ item: thisReco, updatedReco: setups }));
        }
        dispatch(editReco({ item: null }));
    }

    return <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
            Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
            Save Changes
        </Button>
    </Modal.Footer>
}