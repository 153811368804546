import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { db2curr, db2float, float2curr } from '../lib/currLib';

export default function CustListRow(props) {
    const {
        cust,
        c_nm,
        c_ad,
        c_cty,
        c_st,
        c_zp,
        a_tot,
        anc_cur,
        anc_rec,
        anc_chg
    } = props.custRecord;

    const currentAwr = a_tot ? db2curr(a_tot) : float2curr(0);
    const currAncillaries = anc_cur ? db2float(anc_cur) : 0;
    const recoAncillaries = anc_rec ? db2float(anc_rec) : 0;
    const chngAncillaries = anc_chg ? db2float(anc_chg) : recoAncillaries; // no changes to recommendations

    const incRecoAnc = float2curr(recoAncillaries - currAncillaries);
    const incChngAnc = float2curr(chngAncillaries - currAncillaries)

    return (
        <LinkContainer key={cust} to={`/customers/${cust}`} style={{ cursor: 'pointer' }}>
            <tr className="tableRow">
                <td>{c_nm}</td>
                <td>{c_ad}</td>
                <td>{c_cty}</td>
                <td>{c_st}</td>
                <td>{c_zp}</td>
                <td>{currentAwr}</td>
                <td>{incRecoAnc}</td>
                <td>{incChngAnc}</td>
            </tr>
        </LinkContainer>
    )

}