import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editReco } from '../redux/recoStore';
import EditSetupsSwitch from './EditSetupsSwitch';

export default function EditRecoModal(props) {
    const {
        show,
        allRecos
    } = props;

    const recoToEdit = useSelector(state => state.editReco);
    const dispatch = useDispatch();

    const staticReco = allRecos.find(reco => reco.item === recoToEdit) || {};
    const desc = staticReco.desc || "";
    const type = staticReco.type || "";

    const handleCancel = e => {

        e?.preventDefault();
        dispatch(editReco({ item: null }));
    }

    return <Modal
        show={show}
        // size="lg"
        centered
        onHide={handleCancel}
        backdrop="static"
        keyboard={false}
    >

        <Modal.Header>
            <Modal.Title>
                Setups for: <strong>{desc}</strong>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <EditSetupsSwitch
                type={type}
                item={recoToEdit}
                staticReco={staticReco}
                handleCancel={handleCancel}
            />
        </Modal.Body>
    </Modal>
};