import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { str2curr } from '../../../lib/currLib'
import { str2pct } from '../../../lib/percentLib';

export default function AutoReplaceRender(props) {
    const {
        item,
        curr,
        reco
    } = props;

    const chg = useSelector(state => state.recos[item]);

    return <>
        <Row>
            <Col xs={4}>
                AR Percent
            </Col>
            <Col xs className='text-center'>
                {str2pct(curr.pct, 2)}
            </Col>
            <Col xs className='text-center'>
                {str2pct(reco.pct, 2)}
            </Col>
            <Col xs className='text-center'>
                {str2pct(chg.pct, 2)}
            </Col>
        </Row>
        <Row>
            <Col xs={4}>
                Replacement Price
            </Col>
            <Col xs className='text-center'>
                {str2curr(curr.rplcPrc)}
            </Col>
            <Col xs className='text-center'>
                {str2curr(reco.rplcPrc)}
            </Col>
            <Col xs className='text-center'>
                {str2curr(chg.rplcPrc)}
            </Col>
        </Row>
        <Row>
            <Col xs={4}>
                AR Method
            </Col>
            <Col xs className='text-center'>
                {curr.mthd}
            </Col>
            <Col xs className='text-center'>
                {reco.mthd}
            </Col>
            <Col xs className='text-center'>
                {chg.mthd}
            </Col>
        </Row>
    </>
};