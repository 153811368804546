import { API } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Table } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import CustListRow from '../components/CustListRow';
import { onError } from '../lib/errorLib';

export default function CustomerListContainer(props) {
    // get the selected route from the props (which in turn got it from context)
    const { selectedRoute } = props;

    // initiate page states
    const [customers, setCustomers] = useState([]);
    const [exclusiveStartKey, setExclusiveStartKey] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        onLoad();
    }, [selectedRoute])

    const onLoad = async () => {
        try {
            const apiResponse = await loadCusts(selectedRoute);
            
            const { customerHeaders, lastEvaluatedKey: apiLastEvaluated } =  apiResponse;
            
            customerHeaders && setCustomers(customerHeaders);
            apiLastEvaluated && setExclusiveStartKey(apiLastEvaluated);

            setIsLoading(false);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    };

    const loadCusts = async (rte) => {
        
        return API.post('routebot', '/routebot', {
            body: {
                exclusiveStartKey,
                selectedRoute: rte 
            }
        })
    }

    const handleNextClick = async e => {
        e.preventDefault();
        try {
            onLoad();
        } catch (err) {
            onError(err);
        }
    }

    const renderSpinner = () => <FaSpinner />

    const renderCustomerList = () => <Container>
        <Row>
            <Table bordered hover size="sm">
                <thead>
                    <tr>                                
                        <th>Name</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip</th>
                        <th>Total AWR</th>
                        <th>Rec. Inc.</th>
                        <th>Curr. Inc.</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map(customerRecord => <CustListRow key={customerRecord.cust} custRecord={customerRecord} />)}
                </tbody>
            </Table>
        </Row>
        <Row className={"flex-row-reverse"}>
            <Button onClick={handleNextClick}>Next</Button>
        </Row>
    </Container>

    return isLoading ? renderSpinner() : renderCustomerList() ;

}