export function str2pct(pctStr, decimals = 0) {
    try {
        return parseFloat(pctStr).toFixed(decimals) + '%';
    } catch (e) {
        return pctStr;
    }
};

export function float2pct(pctFloat, decimals = 0) {
    try {
        return (pctFloat * 100).toFixed(decimals) + '%';
    } catch {
        return '%';
    }
};