import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Accordion, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CustHeader from '../components/CustDetail/CustHeader';
import RecosAccordionItem from '../components/CustDetail/RecosAccordionItem';
import SaveButton from '../components/CustDetail/SaveButton';
import EditRecoModal from '../components/EditRecoModal';
import { useAppContext } from '../lib/contextLib';
import { float2db } from '../lib/currLib';
import { onError } from '../lib/errorLib';
import parseCustDetail from '../lib/parseCustDetail';
import calcRevenue from '../lib/revenueLib';
import { updateReco } from '../redux/recoStore';

export default function RecosContainer() {
    // get the custId from the path
    const { custId } = useParams();


    // get the selected route from the context
    const { selectedRoute } = useAppContext();

    const [isLoading, setIsLoading] = useState(true);
    const [headerData, setHeaderData] = useState({});
    const [staticRecoData, setStaticRecoData] = useState([]);
    const [editRecoMode, setEditRecoMode] = useState(false);
    const [isPostLOading, setIsPostLoading] = useState(false);

    const dispatch = useDispatch();
    const recoToEdit = useSelector(state => state.editReco);
    const editedRecos = useSelector(state => state.recos);

    const navigate = useNavigate();

    // useEffect for first load
    useEffect(() => {
        onLoad();
    }, [custId, dispatch]);

    // useEffect for when a reco row is selected to edit
    useEffect(() => {
        recoToEdit != null ? setEditRecoMode(true) : setEditRecoMode(false);
    }, [recoToEdit]);

    async function onLoad() {

        try {
            const custDetail = await loadCustomer();
            const parsedData = parseCustDetail(custDetail);
            const recosArr = Array.from(parsedData.recos);
            setHeaderData(parsedData.headerData);
            setStaticRecoData(recosArr);

            // console.log("made it here!");

            recosArr.forEach(reco => {
                const { item, chg } = reco;

                const { awr, ...updatedReco } = chg;
                dispatch(updateReco({ item, updatedReco }));
            })

        } catch (e) {
            onError(e);
        }

        setIsLoading(false);

        function loadCustomer() {
            return API.get("routebot", `/routebot/${custId}`);
        };

    };

    const handleSave = async nextFlag => {
        setIsLoading(true);

        const postChanges = async (nextFlag) => {
            const postArray = [];
            staticRecoData.forEach(rec => {
                const {
                    calcSupport,
                    type,
                    item
                } = rec;
                const thisSetups = editedRecos[item];
                const thisRevenue = float2db(calcRevenue(type, thisSetups, calcSupport));
    
                postArray.push({ item, ...thisSetups, awr: thisRevenue });
            });
    
            return API.post("routebot", `/routebot/${custId}`,
                {
                    body: {
                        postArray,
                        nextFlag,
                        selectedRoute
                    }
                }
            );
        };

        try {
            const { nextCustomer } = await postChanges(nextFlag);
            if (nextCustomer === custId) {
                await onLoad();
            } else if (nextCustomer !== custId) {
                navigate(`/customers/${nextCustomer}`, { replace: true });
            } ;
        } catch (e) {
            onError(e);
        }

    };

    const renderLander = () => <div>LOADING...</div>

    const renderContainer = () => (
        <Container>
            <Row className="py-1">
                <p className="h3">Customer</p>
                <CustHeader headerData={headerData} />
            </Row>
            <Row className="py-1">
                <p className="h3">Ancillary Details</p>
                <Accordion>
                    {staticRecoData.map((recoObj, idx) => {
                        return <RecosAccordionItem key={idx} {...recoObj} />
                    })}
                </Accordion>
            </Row>
                    {
                        [false, true].map((nextFlag, idx) => {
                            return <SaveButton
                                key={idx}
                                nextFlag={nextFlag}
                                loading={isPostLOading}
                                handleSave={handleSave}
                            >Save {nextFlag && `& Next`}</SaveButton>
                        })
                    }
            <EditRecoModal show={editRecoMode} allRecos={staticRecoData} />
        </Container>
    )

    return (<div>{isLoading ? renderLander() : renderContainer()}</div>)
}