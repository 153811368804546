import React from "react";
import { FaCarCrash } from "react-icons/fa";
import "./NotFound.css";

export default function NotFound() {
    return (
        <div className="NotFound text-center">
            <h3><FaCarCrash /> Sorry, page not found!</h3>
        </div>
    );
}