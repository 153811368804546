import { db2float } from "./currLib";

export default function parseCustDetail(arr) {
    const outObj = {};
    const recos = [];
    let incReco = 0;

    arr.map(record => {
        if (record.item.startsWith("hdr")) {
            outObj.headerData = record;
            return outObj;
        } else {
            try {
                incReco = db2float(record.reco.awr) - db2float(record.curr.awr);
            } catch {
                incReco = 0;
            }
            recos.push({
                ...record,
                incReco
            })
            return outObj;
        }
    })

    outObj.recos = recos.sort((a, b) => (a.incReco > b.incReco) ? -1 : 1)

    return outObj;
}