import React from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { db2float } from '../../../lib/currLib';
import { useFormFields } from '../../../lib/hooksLib';
import { calcAutoReplace } from '../../../lib/revenueLib';
import EditSetupsFooter from '../../EditSetupsFooter';
import EditRecoRevenueDisplay from "../../EditRecoRevenueDisplay";

export default function AutoReplaceEdit(props) {
    const {
        item,
        staticReco,
        handleCancel
    } = props;

    const { calcSupport, curr } = staticReco;
    const { mthdOpts } = calcSupport;

    const chg = useSelector(state => state.recos[item]);
    const [setups, handleSetupChange] = useFormFields(chg);

    const revenue = calcAutoReplace(setups, calcSupport);
    const oldRevenue = db2float(curr.awr || null);

    return (
        <>

            <Modal.Body>
                <Row>
                    <Form>
                        <Form.Group
                            controlId="pct"
                            as={Row}
                            className="my-1"
                        >
                            <Form.Label column>Auto Replace Percent (%)</Form.Label>
                            <Col>
                                <Form.Control
                                    value={setups.pct}
                                    onChange={handleSetupChange}
                                    type="number"
                                    step="0.1"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group
                            controlId="rplcPrc"
                            as={Row}
                            className="my-1"
                        >
                            <Form.Label column>Replacement Price ($)</Form.Label>
                            <Col>
                                <Form.Control
                                    value={setups.rplcPrc}
                                    onChange={handleSetupChange}
                                    type="number"
                                    step="0.01"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group
                            controlId="mthd"
                            as={Row}
                            className="my-1"
                        >
                            <Form.Label column>Circulating Inventory (CI)</Form.Label>
                            <Col>
                                <Form.Control
                                    as="select"
                                    value={setups.mthd}
                                    onChange={handleSetupChange}
                                >
                                    {mthdOpts.map((opt, idx) => <option key={idx} value={opt}>{opt}</option>)}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Form>
                </Row>
                <Row>
                    <EditRecoRevenueDisplay revenue={revenue} oldRevenue={oldRevenue} />
                </Row>
            </Modal.Body>
            <EditSetupsFooter
                setups={setups}
                handleCancel={handleCancel}
            />
        </>
    )
}