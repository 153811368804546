import React from "react";
import { Route, Routes as DomRoutes } from "react-router-dom";  
import CustomerListContainer from "./containers/CustomerListContainer";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import RecosContainer from "./containers/RecosContainer";
import ResetPassword from "./containers/ResetPassword";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Routes() {
    return (
        <DomRoutes>
            <Route index element={<Home />} />
            <Route 
                path="/login" 
                element={
                    <UnauthenticatedRoute>
                        <Login />
                    </UnauthenticatedRoute> 
                } 
            />
            <Route 
                path="/customers" 
                element={
                    <AuthenticatedRoute>
                        <CustomerListContainer />
                    </AuthenticatedRoute> 
                } 
            />
            <Route 
                path="/customers/:custId" 
                element={
                    <AuthenticatedRoute>
                        <RecosContainer />
                    </AuthenticatedRoute> 
                } 
            />
            <Route 
                path="/password-reset" 
                element={
                    <AuthenticatedRoute>
                        <ResetPassword />
                    </AuthenticatedRoute> 
                } 
            />
            <Route path="*" element={<NotFound />} />
        </DomRoutes>
    )
};