import React, { useEffect, useState } from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toggleComplete } from '../../redux/recoStore';

export default function CompleteToggler({ item }) {
    const rdxReadyToKey = useSelector(state => state.recos[item].key_rdy);

    const dispatch = useDispatch();

    const handleCompleteToggle = e => {
        dispatch(toggleComplete({ item }));
    };

    return <Form>
        <FormCheck
            type="switch"
            id={item}
            onChange={handleCompleteToggle}
            label="Complete"
            checked={rdxReadyToKey}
        />
    </Form>
}