import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function GuardPackageRender(props) {
    const {
        item,
        curr,
        reco
    } = props;

    const chg = useSelector(state => state.recos[item]);

    return <>
        <Row>
            <Col xs={4}>
                Package
            </Col>
            <Col xs className='text-center'>
                {curr.pkg}
            </Col>
            <Col xs className='text-center'>
                {reco.pkg}
            </Col>
            <Col xs className='text-center'>
                {chg.pkg}
            </Col>
        </Row>
    </>
};