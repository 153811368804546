import React from 'react';
import { Accordion, Row, Col, Button, Badge } from 'react-bootstrap';
import { FaCalculator, FaCheckCircle, FaClipboardList, FaDatabase, FaDollarSign, FaEdit, FaPaperPlane, FaRobot } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { db2curr, db2float, float2curr } from '../../lib/currLib';
import calcRevenue from '../../lib/revenueLib';
import { editReco, updateReco } from '../../redux/recoStore';
import CompleteToggler from './CompleteToggler';
import NewAwrRender from './NewAwrRender';
import SetupsSwitch from './SetupsSwitch';

export default function RecosAccordionItem(props) {
    const {
        desc,
        item,
        type,
        reco,
        curr,
        calcSupport
    } = props;

    const dispatch = useDispatch();

    const chg = useSelector(state => state.recos[item]);

    const setupAwr = calcRevenue(type, chg, calcSupport);
    const readyToKey = chg.key_rdy;
    const bgColor = readyToKey ? "#DDDDDD" : "#FFFFFF"
    const rowStyle = { backgroundColor: bgColor };

    const recoAwr = db2curr(reco.awr);
    const currAwr = db2curr(curr.awr);
    const chgAwr = float2curr(setupAwr);

    const gainAwr = setupAwr - db2float(curr.awr);

    const handleOldSetupsButtonClick = (e) => {
        e?.preventDefault();

        const { awr, ...updatedReco } = curr;

        if (!readyToKey) {
            dispatch(updateReco({
                item,
                updatedReco: {
                    ...updatedReco,
                    key_rdy: readyToKey
                }
            }));
        }
    };

    const handleRecoSetupsButtonClick = (e) => {
        e?.preventDefault();

        const { awr, ...updatedReco } = reco;

        if (!readyToKey) {
            dispatch(updateReco({
                item,
                updatedReco: {
                    ...updatedReco,
                    key_rdy: readyToKey
                }
            }));
        }
    };

    const handleEditReco = (e) => {
        e?.preventDefault();

        if (!readyToKey) { dispatch(editReco({ item })) };
    };

    // const handleCompleteToggle = (e) => {
    //     e?.preventDefault();
    //     dispatch(toggleComplete({ item }));
    // }

    return (
        <Accordion.Item eventKey={item} style={rowStyle}>
            <Accordion.Header>{desc}  {readyToKey && <Badge bg="success" className="mx-2">Reviewed</Badge>}</Accordion.Header>
            <Accordion.Body>
                <Row className="h5 text-center">
                    <Col xs={2} className="p-1 border">
                        <FaDollarSign /> AWR
                    </Col>
                    <Col xs={6} className="p-1 border">
                        <FaCalculator /> Setups
                    </Col>
                    <Col xs={2} className="p-1 border">
                        <FaPaperPlane /> New AWR
                    </Col>
                    <Col xs={2} className="p-1 border">
                        <FaCheckCircle /> Complete
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} className="px-1 m-auto text-center">
                        <Row>
                            <Col xs>
                                <FaDatabase /> Old
                            </Col>
                            <Col xs>
                                {currAwr}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs>
                                <FaRobot /> Rec.
                            </Col>
                            <Col xs>
                                {recoAwr}
                            </Col>

                        </Row>
                    </Col>
                    <Col xs={6} className="px-1">
                        <Row xs className='text-center'>
                            <Col xs={4}>
                                <FaClipboardList /> Setup
                            </Col>
                            <Col xs>
                                <Button
                                    variant="secondary"
                                    onClick={handleOldSetupsButtonClick}
                                    disabled={readyToKey}
                                >
                                    <FaDatabase /> Old
                                </Button>
                            </Col>
                            <Col xs>
                                <Button
                                    variant="primary"
                                    onClick={handleRecoSetupsButtonClick}
                                    disabled={readyToKey}
                                >
                                    <FaRobot /> Rec.
                                </Button>
                            </Col>
                            <Col xs>
                                <Button
                                    variant="success"
                                    onClick={handleEditReco}
                                    disabled={readyToKey}
                                >
                                    <FaEdit /> New
                                </Button>
                            </Col>
                        </Row>
                        <SetupsSwitch
                            type={type}
                            curr={curr}
                            reco={reco}
                            calcSupport={calcSupport}
                            item={item}
                        />
                    </Col>
                    <Col xs={2} className="px-1 m-auto text-center">
                        <NewAwrRender chgAwr={chgAwr} gainAwr={gainAwr} />
                    </Col>
                    <Col xs={2} className="px-1 m-auto text-center">
                        <CompleteToggler item={item} />
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}