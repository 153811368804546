import { API, Auth } from "aws-amplify";

export const apiGet = async (apiName, path) => {
    API.get(apiName, path)
};

export const apiPost = async (apiName, path, bodyPayload) => {
    API.post(apiName, path, {
        body: bodyPayload
    });
};