export function db2float(dbstr) {
    try {
        return parseFloat(dbstr) / 100;
    } catch {
        return parseFloat(0);
    };
}

export function float2curr(currflt) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(currflt);
}

export function db2curr(dbstr) {
    try {
        return float2curr(db2float(dbstr));
    } catch {
        return float2curr(0);
    };
};

export function str2curr(currStr) {
    try {
        return float2curr(parseFloat(currStr));
    } catch {
        return currStr;
    }
}

export function float2db(currFlt) {
    return (currFlt.toFixed(2) * 100).toString();
}