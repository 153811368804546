import React, { useEffect, useState } from "react";
import { FaRobot } from "react-icons/fa";
import RouteSelect from "../components/RouteSelect";
import { useAppContext } from "../lib/contextLib";
import CustomerListContainer from "./CustomerListContainer";
import "./Home.css";

export default function Home() {

    const { isAuthenticated, selectedRoute } = useAppContext();

    const renderLander = () => {
        return (
            <div className="lander">
                <h1><FaRobot /> Routebot</h1>
                <div className="text-muted">Your Automatic Invoice Reviewer</div>
            </div>
        )
    };

    const renderAuthenticatedHome = () => {
        if (!selectedRoute) {
            return <RouteSelect 
                    show={true} 
                    handleModalClose={e => e.preventDefault()} // don't do anything
                    currentlySelectedRoute={undefined} // there's no selected route
                />
        } else {
           return <CustomerListContainer selectedRoute={selectedRoute} />
        }
    }

    return (
        <div className="Home">
            {isAuthenticated ? renderAuthenticatedHome() : renderLander()}
        </div>
    );
}