import { db2float } from "./currLib";

export const calcAutoReplace = (setups, calcSupport) => {
    const {
        pct,
        rplcPrc,
        mthd
    } = setups;

    const {
        trnIn,
        ci
    } = calcSupport;

    const multiplier = mthd === 'CI' ? parseFloat(ci) : parseFloat(trnIn);

    return multiplier * (parseFloat(pct) / 100) * parseFloat(rplcPrc);
};

export const calcEnergyWasteWater = (setups, calcSupport) => {
    const {
        pct,
        amt
    } = setups;

    const {
        baseAwr
    } = calcSupport;

    const pctAmt = db2float(baseAwr) * (parseFloat(pct) / 100);

    return pctAmt > parseFloat(amt) ? pctAmt : parseFloat(amt);
};

export const calcGuardPackage = (setups, calcSupport) => {
    const {
        pkg
    } = setups;

    const {
        pkgAwrs
    } = calcSupport;

    try {
        return db2float(JSON.parse(pkgAwrs)[pkg])
    } catch {
        return db2float(pkgAwrs[pkg]);
    }
};

export default function calcRevenue(type, setups, calcSupport) {
    switch (type) {
        case 'autoReplace':
            return calcAutoReplace(setups, calcSupport)
        case 'energy':
            return calcEnergyWasteWater(setups, calcSupport)
        case 'wasteWater':
            return calcEnergyWasteWater(setups, calcSupport)
        case 'imageGuard':
            return calcGuardPackage(setups, calcSupport)
        case 'prepGuard':
            return calcGuardPackage(setups, calcSupport)
        default:
            return 0
    }
}