import React from 'react';
import { Col, Row, Accordion } from 'react-bootstrap';
import { FaDatabase, FaPaperPlane, FaRobot } from 'react-icons/fa';
import { db2curr, db2float, float2curr } from '../../lib/currLib';
import { float2pct } from '../../lib/percentLib';
import "./CustHeader.css"

export default function CustHeader(props) {
    const {
        c_nm: name,
        c_ad: address,
        c_cty: city,
        c_st: st,
        c_zp: zip,
        c_fd: firstDeliveryDate,
        a_tot,
        anc_chg,
        anc_cur,
        anc_rec,
    } = props.headerData;

    const ancCur = db2float(anc_cur);
    const ancRec = db2float(anc_rec);
    const ancChg = db2float(anc_chg);

    const chgGain = ancChg - ancCur;

    return (
        <Accordion>
            <Accordion.Item eventKey='0'>
                <Accordion.Header>{name}</Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col sm={6}>
                            <div className="lead">Address:</div>
                            {address} <br />
                            {city}, {st} {zip}
                        </Col>
                        <Col sm={6}>
                            <div className="lead">First Delivery Date: </div>
                            {firstDeliveryDate}
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
                <Accordion.Header>Starting AWR: {db2curr(a_tot)} | Expected Gain: {float2curr(chgGain)} ({float2pct(chgGain / db2float(a_tot))})</Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col md>
                            <Row className="lead">Current AWR:</Row>
                            <Row>
                                <Col xs>
                                    Total:
                                </Col>
                                <Col xs>
                                    {db2curr(a_tot)}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    Base invoice:
                                </Col>
                                <Col xs>
                                    {float2curr(db2float(a_tot) - ancCur)}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    Ancillaries:
                                </Col>
                                <Col xs>
                                    {float2curr(ancCur)}
                                </Col>
                            </Row>
                        </Col>
                        <Col md>
                            <Row className="lead">Ancillaries:</Row>
                            <Row>
                                <Col sm>
                                    <FaDatabase className="mx-2" />Old:
                                </Col>
                                <Col sm>
                                    {float2curr(ancCur)}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm>
                                    <FaRobot className="mx-2" />Recommended:
                                </Col>
                                <Col sm>
                                    {float2curr(ancRec)}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm>
                                    <FaPaperPlane className="mx-2" />New:
                                </Col>
                                <Col sm>
                                    {float2curr(ancChg)}
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}