import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaDollarSign } from 'react-icons/fa';
import { float2curr } from "../../lib/currLib";

export default function NewAwrRender({ chgAwr, gainAwr }) {
    const revenueGainFlg = gainAwr >= 0;
    const chgColor = revenueGainFlg ? 'green' : 'red';
    const chgStyle = {
        display: 'inline',
        color: chgColor,
        fontWeight: 'bold'
    };

    return <>
        <Row>
            <Col>
                <FaDollarSign />AWR
            </Col>
            <Col xs>
                {chgAwr}
            </Col>
        </Row>
        <Row>
            <Col>
                <strong>Gain/Loss</strong>
            </Col>
            <Col xs>
                <div style={chgStyle}>{revenueGainFlg ? '+' : '-'}{float2curr(Math.abs(gainAwr))}</div>
            </Col>
        </Row>
    </>
}