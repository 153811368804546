import React from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { db2float } from '../../../lib/currLib';
import { useFormFields } from '../../../lib/hooksLib';
import { calcGuardPackage } from '../../../lib/revenueLib';
import EditSetupsFooter from '../../EditSetupsFooter';
import EditRecoRevenueDisplay from "../../EditRecoRevenueDisplay";

export default function GuardPackageEdit(props) {
    const {
        item,
        staticReco,
        handleCancel
    } = props;

    const { calcSupport, curr } = staticReco;
    const { pkgOptions } = calcSupport;

    const chg = useSelector(state => state.recos[item]);
    const [setups, handleSetupChange] = useFormFields(chg);

    const revenue = calcGuardPackage(setups, calcSupport);
    const oldRevenue = db2float(curr.awr || null);

    return (
        <>
            <Modal.Body>
                <Row>
                    <Form>
                        <Form.Group
                            controlId="pkg"
                            as={Row}
                            className="my-1"
                        >
                            <Form.Label column>Package</Form.Label>
                            <Col>
                                <Form.Control
                                    as="select"
                                    value={setups.pkg}
                                    onChange={handleSetupChange}
                                >
                                    {pkgOptions.map((opt, idx) => <option key={idx} value={opt}>{opt}</option>)}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Form>
                </Row>
                <Row>
                    <EditRecoRevenueDisplay revenue={revenue} oldRevenue={oldRevenue} />
                </Row>
            </Modal.Body>
            <EditSetupsFooter
                setups={setups}
                handleCancel={handleCancel}
            />
        </>
    )
}