import React from 'react';
import {Button, Spinner} from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { resetState } from '../../redux/recoStore';


export default function SaveButton(props) {
    const {
        nextFlag,
        loading,
        handleSave,
        children
    } = props;

    // const dispatch = useDispatch();

    const buttonVariant = nextFlag ? "primary" : "secondary";

    return <Button 
        className="mx-1" 
        variant={buttonVariant}
        onClick={() => handleSave(nextFlag)}
    >
        {loading ? <Spinner animation="border" variant="light" /> : children}
    </Button>
}