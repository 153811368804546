import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BsArrowRepeat } from 'react-icons/bs';
import { onError } from '../lib/errorLib';
import "./RouteSelect.css"

export default function RouteSelect(props) {

    // Dropdown Form setups
    const startingOption = "Select a Route";
    const [selectedRoute, setSelectedRoute] = useState(startingOption);
    const [isLoading, setIsLoading] = useState(true);
    const [availableRoutes, setAvailableRoutes] = useState([startingOption]);
    
    const {
        show,
        handleModalClose,
        currentlySelectedRoute
    } = props;

    useEffect(() => {

        async function onLoad() {
            try {
                const loadedRoutes = await loadRoutes();
                // const parsedRoutes = JSON.parse(loadedRoutes);
                setAvailableRoutes([startingOption, ...loadedRoutes]);

            } catch (e) {
                onError(e)
            }
            
            setIsLoading(false);
        }

        onLoad();
        
    }, [show]);

    function loadRoutes() {
        return API.get("routebot", "/routebot");
    }

    const handleChange = e => {
        setSelectedRoute(e.target.value);
    };

    const handleSubmit = e => {
        e.preventDefault();
        console.log("submitted! the selected route is: " + selectedRoute);
        localStorage.setItem("selectedRoute", selectedRoute);
        window.location.reload(false);
    };
    
    const renderModalBody = () => <Modal.Body>
        <Form.Control
            as="select"
            value={selectedRoute}
            onChange={handleChange}
        >
            {
                availableRoutes.map(((routeOption) => (
                    <option key={routeOption}>{routeOption}</option>
                )))
            }
        </Form.Control>
    </Modal.Body>

    return <Modal show={show} backdrop="static">
        <Modal.Header>
            {currentlySelectedRoute 
                ? 
                "Currently Selected Route: " + currentlySelectedRoute + ". Change to:"
                :
                "Select a Route to Analyze:"
            }
        </Modal.Header>
        {isLoading ? <BsArrowRepeat className="spinning" /> : renderModalBody()}
        <Modal.Footer>
            {currentlySelectedRoute && <Button variant="secondary" onClick={handleModalClose}>Close</Button>}
            <Button 
                variant="primary" 
                onClick={handleSubmit} 
                disabled={selectedRoute === startingOption}
            >Select</Button>
        </Modal.Footer>
    </Modal>
}