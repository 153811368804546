import React from 'react';
import AutoReplaceRender from '../setupTypes/autoReplace/AutoReplaceRender';
import EnergyWasteWaterRender from '../setupTypes/energyWasteWater/EnergyWasteWaterRender';
import GuardPackageRender from '../setupTypes/guardPackage/GuardPackageRender';

export default function SetupsSwitch(props) {
    switch (props.type) {
        case 'autoReplace':
            return <AutoReplaceRender {...props} />
        case 'imageGuard':
            return <GuardPackageRender {...props} />
        case 'prepGuard':
            return <GuardPackageRender {...props} />
        case 'energy':
            return <EnergyWasteWaterRender {...props} />
        case 'wasteWater':
            return <EnergyWasteWaterRender {...props} />
        default:
            return <></>
    }
};