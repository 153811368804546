import React from 'react';
import EditSetupsFooter from './EditSetupsFooter';
import AutoReplaceEdit from './setupTypes/autoReplace/AutoReplaceEdit';
import EnergyWasteWaterEdit from './setupTypes/energyWasteWater/EnergyWasteWaterEdit';
import GuardPackageEdit from './setupTypes/guardPackage/GuardPackageEdit';

export default function EditSetupsSwitch(props) {
    switch (props.type) {
        case 'autoReplace':
            return <AutoReplaceEdit {...props} />
        case 'energy':
            return <EnergyWasteWaterEdit {...props} />
        case 'wasteWater':
            return <EnergyWasteWaterEdit {...props} />
        case 'imageGuard':
            return <GuardPackageEdit {...props} />
        case 'prepGuard':
            return <GuardPackageEdit {...props} />
        default:
            return <EditSetupsFooter {...props} />
    }
}